@import "~bootstrap/scss/bootstrap";
@import "./basedata/variables";

body {
  min-height: 100vh;
  background: #ccc !important;
  // background: url('./images/bg_transition_2.jpg') center center  no-repeat
 }  // background-size: cover

.main-loading {
  width: 100%;
  height: 100vh;
  text-align: center;
  padding-top: 60%;
  background-color: rgba(255, 255, 255, 0.0 );
  transition: all 0.7s;

  &.active {
    background-image: none;
    // background-color: $pm-primary

    background: rgb(26,137,201);
    // background: linear-gradient(180deg, rgba(26,137,201,1) 0%, #064467 100%)

    background: radial-gradient(ellipse at left bottom, $pm-primary 0%, #012337 100%);



    .logo {
      max-width: 160px; } }

  .logo {
    max-width: 290px;
    transition: ease all 0.5s; } }

.Container {
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    max-width: 160px; }

  .logos {
    text-align: center;
    margin: 2em 0; }

  .logo-bet {
    display: block;
    width: 45%;
    margin: 0 auto; } }

.card {
  padding: 24px;
  background: none !important;
  width: 80%;
  max-width: 400px;
  border: none !important;
  transition: all ease 2s;

  h1 {
    width: 100%;
    @include fontDefault;
    font-size: 2em;
    color: $white;

    span {
      @include fontSuper; } }

  p {
    @include fontDefault;
    font-size: 1em;
    line-height: 1.3em;
    color: $white;

    &.loading {
      min-height: 300px;
      text-align: center;
      background-color: #2c661f;
      color: #ffffff;
      justify-content: center;

      h1 {
        display: none; } }

    .form-group {
      padding: 8px;

      .primary {
        background-color: $pm-dark;
        color: #ffffff; } }
    .text-muted {
      color: #cb6a6a !important; } } }

.form-wrapper {
  padding-top: 24px;

  .form-floating {
    margin: 8px 0;
    label {
      @include fontDefault;
      padding-bottom: 4px;
      color: $gray-600; }

    input {
      border-radius: 4px;
      @include fontDefault;
      font-size: 1em;
      color: $gray-700; } } }

.form-submit {
  // border-top: 1px solid tint-color($pm-primary, 80%)
  padding-top: 24px;
  margin-top: 24px;

  .btn-primary {
    background-color: $white !important;

    @include fontTitle;
    color: $pm-primary;
    border: none; } }

.cart.zedobigode {
  .btn-primary {
    background-color: $pm-primary !important;
    color: $white; } }

.help-link {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  text-decoration: none;
  color: $pm-primary; }

.form-check {
  display: flex;
  align-items: center; }

.form-check-label {
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  line-height: 1.2em;
  text-decoration: none;
  color: $gray-600;
  margin-left: 16px;

  a {
    @include fontTitle;
    color: $pm-dark; } }

.label-error {
  @include fontDefault;
  font-size: 0.8em;
  color: $red-400; }

.step-counter {
  width: 100%;
  text-align: center;
  margin-top: 8px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 8px;
    border-radius: 50%;
    border: 1px solid $white;
    padding: 4px;

    &.active {
      background-color: tint-color($pm-primary, 80%);
      border-color: tint-color($pm-primary, 80%); } } }

.copyright {
  text-align: center;
  @include fontDefault;
  font-size: 0.8em;
  color: $white;

  a {
    text-decoration: none;
    color: $white;
    vertical-align: middle;
    &:hover {
      color: $pm-primary; } }

  .logo-bepass {
    max-width: 90px;
    margin-left: 6px; } }


.cart {
  padding: 20px;
  width: 100%;
  // align-items: flex-start


  .logo {
    max-width: 160px; }

  .card {
    max-width: 100%;
    width: 100%;
    background: $white !important;
    margin-bottom: 20px;
    padding: 0; } }

.product-image {
  width: 70px;
  img {
    width: 100%; } }

.col-header {
  font-size: 10px;
  text-transform: uppercase; }

.col-body {
  font-size: 14px; }

.col-footer {
  font-weight: bold;
  font-size: 14px; }


.zedobigode {
  padding-top: 20%;

  form.card {
    opacity: 0;

    &.active {
      opacity: 1; } } }

.proccess-card {
  .lds-ring div {
    border-color: $white transparent transparent transparent; } }

.text-left {
  text-align: left; }




.loading-handler-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;

  img {
    width: 65%;
    padding: 8% 20%; }

  span {
    font-size: 2em;
    padding: 0 20%;
    margin-bottom: 0.8em;
    color: $black;

    &.caption {
      font-size: 1.2em;
      color: $gray-800; } } }
