
@import "./../../basedata/variables";

// Loading
.lds-ring {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0% auto 10% auto;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 164px;
    height: 164px;
    margin: 8px;
    border: 6px solid $pm-primary;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $pm-primary transparent transparent transparent;

    div:nth-child(1) {
      animation-delay: -0.45s;
      opacity: 0.8; }

    div:nth-child(2) {
      animation-delay: -0.3s;
      opacity: 0.5; }

    div:nth-child(3) {
      animation-delay: -0.15s;
      opacity: 0.3; } } }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }
